/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Resource } from './Resource';
export type TimeTrackingForceStopEventBody = {
    action: TimeTrackingForceStopEventBody.action;
    reference: string;
    resource: Resource;
};
export namespace TimeTrackingForceStopEventBody {
    export enum action {
        FORCE_STOP = 'force_stop',
    }
}

