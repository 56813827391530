import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";

import { envFromHostname } from "~lib/envHelpers.ts";

import { config } from "./config.ts";

export function init() {
  const env = envFromHostname();

  if (config().sentryDsn) {
    Sentry.init({
      dsn: config().sentryDsn,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      environment: env,
      release: `grewe-gruppe-app@${config().gitSha}`,
      tracesSampleRate: Number(config().sentrySampleRate) || 0.0,
      // debug: true,
    });
    Sentry.setContext("git", {
      sha: config().gitSha || "unknown",
    });
  }
}
