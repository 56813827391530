import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { SearchField } from "~components/shared/SearchField/SearchField";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import { DayTitle } from "./DayTitle";
import { NotificationCard } from "./Notification/NotificationCard";

import type {
  EmbeddedNotification,
  GroupedNotifications,
} from "./useEmbeddedNotifications";
import type { NotificationFilters } from "./useNotificationsView";

type NotificationCardsProps = {
  groupedNotifications: GroupedNotifications;
  onSelectNotification: (selectedNotification: EmbeddedNotification) => void;
  notificationFilters: NotificationFilters;
  notificationCount?: number;
  markAllNotificationsRead: () => void;
};
export function NotificationCards({
  groupedNotifications,
  onSelectNotification,
  notificationFilters,
  notificationCount,
  markAllNotificationsRead,
}: NotificationCardsProps) {
  const t = useTranslation();

  return (
    <>
      <Stack gap={2}>
        <Filters {...notificationFilters} />
        <Button
          variant="containedInverted"
          fullWidth
          onClick={markAllNotificationsRead}
          startIcon={<MarkEmailReadIcon />}
          disabled={notificationCount === 0 || notificationCount === undefined}
        >
          {t.notificationsMarkAsRead}
        </Button>
      </Stack>
      {groupedNotifications.length === 0 && (
        <div style={{ marginTop: "1em" }}>{t.notificationsEmptyResult}</div>
      )}
      {groupedNotifications.map(([date, notifications]) => (
        <div key={date.toISOString()}>
          <Box
            sx={{
              mt: 4,
              mb: 2,
              fontSize: "0.75rem",
              textTransform: "uppercase",
            }}
          >
            <DayTitle date={date} />
          </Box>
          <Stack gap={1}>
            {notifications.map((notification) => (
              <NotificationCard
                notification={notification}
                onSelectNotification={onSelectNotification}
                key={notification.id}
              />
            ))}
          </Stack>
        </div>
      ))}
    </>
  );
}

function Filters({
  unreadOnly,
  setUnreadOnly,
  searchTerm,
  setSearchTerm,
}: NotificationFilters) {
  const t = useTranslation();

  return (
    <>
      <Tabs
        value={unreadOnly}
        onChange={(_, newValue) => setUnreadOnly(newValue)}
        sx={{ mb: "1rem", borderBottom: 1, borderColor: "divider" }}
      >
        <Tab
          sx={{ width: "50%" }}
          value={"unread"}
          label={t.notificationsFilterUnread}
        />
        <Tab sx={{ width: "50%" }} value={"all"} label={t.allEntries} />
      </Tabs>
      <SearchField value={searchTerm} onChange={setSearchTerm} />
    </>
  );
}
