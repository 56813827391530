import OfflineReadyIcon from "@mui/icons-material/SignalCellularConnectedNoInternet1BarOutlined";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink } from "react-router";

import { useCurrentEmployeeTenants } from "~api/tenants";
import logoIcon from "~assets/svg/grewe-logo-icon.svg";
import logo from "~assets/svg/logo.svg";
import { ROUTES } from "~components/routeDefinitions.ts";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { useOfflineContext } from "~contexts/OfflineContext/useOfflineContext";
import { useUserProfile } from "~contexts/UserProfileContext/UserProfileContext.tsx";
import { envFromHostname } from "~lib/envHelpers";

import { HeaderMenuIconButton } from "./HeaderMenuIconButton";
import { ManagerSelect } from "./ManagerSelect/ManagerSelect";
import { SettingsButton } from "./SettingsButton/SettingsButton";
import { Sync } from "./Sync/Sync";
import { TenantMenu } from "./TenantMenu/TenantMenu.tsx";
import { UserMenu } from "./UserMenu/UserMenu.tsx";

import styles from "./Header.module.scss";

const IS_PRODUCTION = envFromHostname() === "production";

export function Header() {
  const userProfile = useUserProfile();
  const theme = useTheme();
  const { data: tenants } = useCurrentEmployeeTenants();
  const { number: tenantNumber } = useCurrentTenant();
  const isUpMedium = useMediaQuery(theme.breakpoints.up("md"));
  const logoSrc = isUpMedium ? logo : logoIcon;
  const tenantArray = tenants || [];
  const { isOfflineReady } = useOfflineContext();

  return (
    <div className={styles.header}>
      <div className={styles.header__left}>
        <NavLink
          className={styles.logo}
          to={ROUTES.dashboard.link({ tenantNumber })}
        >
          <img src={logoSrc} alt="Grewe Logo" />
        </NavLink>
        <div className={styles.header__left__menus}>
          <TenantMenu tenants={tenantArray} />
          <ManagerSelect />
        </div>
      </div>
      <div className={styles.header__right}>
        {!IS_PRODUCTION && isOfflineReady && (
          <Tooltip title="DEV: offline ready">
            <HeaderMenuIconButton
              disableRipple
              icon={<OfflineReadyIcon fontSize="inherit" />}
            />
          </Tooltip>
        )}
        <Sync />
        <SettingsButton />
        <UserMenu userName={userProfile?.name} />
      </div>
    </div>
  );
}
