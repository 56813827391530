import { Navigate } from "react-router";

import { useCurrentEmployeeTenants } from "~api/tenants.ts";
import { anyAbility } from "~auth/Ability.ts";
import { usePermitted } from "~auth/usePermitted.ts";
import { ROUTES } from "~components/routeDefinitions.ts";
import { Broken } from "~components/shared/ErrorPages/Broken.tsx";
import { Forbidden } from "~components/shared/ErrorPages/Forbidden.tsx";
import { Spinner } from "~components/shared/Loading/Spinner/Spinner.tsx";
import { ApiError } from "~generated";

export function RedirectToMainTenant() {
  const {
    data: tenants,
    isError: isTenantsError,
    error,
  } = useCurrentEmployeeTenants();
  const permitted = usePermitted();

  if (error instanceof ApiError && error.status === 401) {
    return <Forbidden />;
  }

  if (isTenantsError) {
    return <Broken />;
  }

  if (!permitted(anyAbility) || (tenants && tenants.length === 0)) {
    return <Forbidden />;
  }

  if (permitted(anyAbility) && tenants && tenants.length > 0) {
    return (
      <Navigate
        to={ROUTES.dashboard.link({ tenantNumber: tenants[0].number })}
      />
    );
  }

  return <Spinner />;
}
