import List from "@mui/material/List";
import { NavLink } from "react-router";

import { classList } from "~lib/classList.ts";

import { SidebarFooter } from "./SidebarFooter.tsx";
import { SidebarMenuItem } from "./SidebarMenuItem.tsx";

import type { HTMLAttributes, ReactNode } from "react";
import type { NavLinkProps } from "react-router";

import styles from "./SidebarMenu.module.scss";

interface SidebarMenuProps extends HTMLAttributes<HTMLDivElement> {
  sidebarOpen: boolean;
}

export function SidebarMenu({
  sidebarOpen,
  className,
  children,
  ...props
}: SidebarMenuProps) {
  return (
    <nav
      {...props}
      data-testid="sidebar-navigation"
      aria-expanded={sidebarOpen}
      className={classList(styles.nav, className)}
    >
      <List>{children}</List>
      <SidebarFooter sidebarOpen={sidebarOpen} />
    </nav>
  );
}

interface SidebarMenuNavLinkProps extends Omit<NavLinkProps, "children"> {
  title: string;
  icon: ReactNode;
}

export function SidebarMenuNavLink({
  title,
  icon,
  ...props
}: SidebarMenuNavLinkProps) {
  return (
    <NavLink {...props}>
      {({ isActive }) => (
        <SidebarMenuItem title={title} icon={icon} isActive={isActive} />
      )}
    </NavLink>
  );
}
