import { useNavigate } from "react-router";

import { ROUTES } from "~components/routeDefinitions.ts";
import { useCurrentEmployeeNewestRunningTimeTracking } from "~components/TimeManagement/api/timeTracking.ts";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";

import { useTimeTrackingTitle } from "../shared/useTimeTrackingTitle/useTimeTrackingTitle.ts";

import { RunningTimeTrackingBannerView } from "./RunningTimeTrackingBannerView.tsx";

export function RunningTimeTrackingBanner() {
  const navigate = useNavigate();
  const { number: tenantNumber } = useCurrentTenant();

  const runningTimeTracking = useCurrentEmployeeNewestRunningTimeTracking();

  const { isLoading, title } = useTimeTrackingTitle(runningTimeTracking);

  if (!runningTimeTracking || isLoading) {
    return <></>;
  }

  const titleStr = `${title.primary} - ${title.secondary}`;

  return (
    <div
      onClick={() =>
        navigate(
          ROUTES.timeTracking.link({
            tenantNumber,
            timeTrackingId: runningTimeTracking.id,
          }),
        )
      }
    >
      <RunningTimeTrackingBannerView title={titleStr} />
    </div>
  );
}
