import { useContext } from "react";
import { Navigate, useLocation } from "react-router";

import {
  ROUTES,
  getMatchedRouteForPathname,
} from "~components/routeDefinitions.ts";
import { CurrentTenantContext } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";

import { usePermitted } from "./usePermitted";

interface RequirePermitProps {
  children: JSX.Element;
}

export function RequirePermit({ children }: RequirePermitProps) {
  const permitted = usePermitted();
  const location = useLocation();
  const { currentTenant } = useContext(CurrentTenantContext);

  const { pathname } = useLocation();

  const { route } = getMatchedRouteForPathname(pathname);

  if (!currentTenant) {
    return <></>;
  }
  if (route && permitted(route.permissions)) {
    return <>{children}</>;
  }

  return (
    <Navigate
      to={ROUTES.dashboard.link({ tenantNumber: currentTenant.number })}
      state={{ from: location }}
      replace
    />
  );
}
