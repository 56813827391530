import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router";

import { ROUTES } from "~components/routeDefinitions.ts";
import {
  Priority,
  TaskBadge,
  TaskBar,
} from "~components/shared/TaskBar/TaskBar.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { TimeEntryStatus } from "~generated";
import { useCommuteExpensesFeatureEnabled } from "~lib/featureToggles/useCommuteExpensesFeatureEnabled.ts";

import {
  AGE_TO_DAYS,
  Age,
  useTimeApprovalTaskCounts,
} from "./useTimeApprovalTaskCounts.ts";

import type { TaskBarProps } from "~components/shared/TaskBar/TaskBar.tsx";
import type { Translations } from "~contexts/I18nContext/I18nContext.tsx";

export type TimeApprovalTaskBarProps = {
  variant?: TaskBarProps["variant"];
};

const AGE_TO_PRIORITY = {
  [Age.ANCIENT]: Priority.HIGH,
  [Age.OLD]: Priority.MEDIUM,
  [Age.YOUNG]: Priority.NORMAL,
};

export function TimeApprovalTaskBar({
  variant = "horizontal",
}: TimeApprovalTaskBarProps) {
  const t = useTranslation();
  const commuteExpensesFeatureEnabled = useCommuteExpensesFeatureEnabled();
  const {
    managerAccountCount,
    managerAccountAge,
    openCount,
    openAge,
    conflictCount,
    conflictAge,
    anomaliesCount,
    anomaliesAge,
  } = useTimeApprovalTaskCounts();
  const navigate = useNavigate();
  const { number: tenantNumber } = useCurrentTenant();
  const location = useLocation();

  const navigateToTimeApprovalTask = async (taskType: string) => {
    const path = ROUTES.timeApproval.link({ tenantNumber });
    await navigate(`${path}?taskType=${taskType}`, {
      replace: location.pathname === path,
    });
  };

  const onSelectStatus = (status: TimeEntryStatus) =>
    navigateToTimeApprovalTask(status);
  const onSelectConflict = () => navigateToTimeApprovalTask("conflict");
  const onSelectAnomalies = () => navigateToTimeApprovalTask("anomalies");

  return (
    <Box sx={{ paddingBottom: "1rem" }}>
      <TaskBar variant={variant}>
        <TaskBadge
          count={managerAccountCount}
          text={t.timeEntryStatusManagerAccount}
          onClick={() => onSelectStatus(TimeEntryStatus.MANAGER_ACCOUNT)}
          priority={AGE_TO_PRIORITY[managerAccountAge]}
          tooltip={taskBadgeTooltip({ t, age: managerAccountAge })}
        />
        <TaskBadge
          count={openCount}
          text={t.timeEntryStatusOpen}
          onClick={() => onSelectStatus(TimeEntryStatus.OPEN)}
          priority={AGE_TO_PRIORITY[openAge]}
          tooltip={taskBadgeTooltip({ t, age: openAge })}
        />
        <TaskBadge
          count={conflictCount}
          text={
            conflictCount === 1
              ? t.timeEntryStatusConflict
              : t.timeEntryStatusConflicts
          }
          onClick={onSelectConflict}
          priority={Priority.HIGH}
          tooltip={taskBadgeTooltip({ t, age: conflictAge })}
        />
        {commuteExpensesFeatureEnabled && (
          <TaskBadge
            count={anomaliesCount}
            text={
              anomaliesCount === 1
                ? t.timeEntryCommuteAnomaly
                : t.timeEntryCommuteAnomalies
            }
            onClick={onSelectAnomalies}
            priority={Priority.MEDIUM}
            tooltip={taskBadgeTooltip({ t, age: anomaliesAge })}
          />
        )}
      </TaskBar>
    </Box>
  );
}

function taskBadgeTooltip({ t, age }: { t: Translations; age: Age }) {
  return age !== Age.YOUNG
    ? t.taskBarAgeTooltip.replace("{ageInDays}", AGE_TO_DAYS[age].toString())
    : "";
}
