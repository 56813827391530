import Button from "@mui/material/Button";
import { Link } from "react-router";

import { ROUTES } from "~components/routeDefinitions.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import type { Tenant } from "~generated";

import styles from "./ErrorPages.module.scss";

interface TenantForbiddenProps {
  redirectToTenant: Tenant;
  forbiddenTenantName?: string;
}

export function TenantForbidden({
  redirectToTenant,
  forbiddenTenantName,
}: TenantForbiddenProps) {
  const t = useTranslation();

  const headerText = t.tenantForbiddenHeader.replace(
    "{forbiddenTenantName}",
    forbiddenTenantName || "",
  );

  const bodyText = t.tenantForbiddenText
    .replace("{forbiddenTenantName}", forbiddenTenantName || "")
    .replace("{allowedTenantName}", redirectToTenant.name);

  const linkText = t.tenantForbiddenLink.replace(
    "{allowedTenantName}",
    redirectToTenant.name,
  );

  return (
    <div className={styles.container}>
      <h1>{headerText}</h1>
      <div>
        <p dangerouslySetInnerHTML={{ __html: bodyText }} />
        <p>
          <Link
            to={ROUTES.dashboard.link({
              tenantNumber: redirectToTenant.number,
            })}
          >
            <Button>{linkText}</Button>
          </Link>
        </p>
        <p className={styles.contacts}>
          {t.forbiddenFooter} ({t.forbiddenContacts})
        </p>
      </div>
    </div>
  );
}
