import BackIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useNavigate } from "react-router";

import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList.ts";

import styles from "./PageLayoutHeader.module.scss";

export interface PageLayoutHeaderProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
  isHome?: boolean;
  isLoading?: boolean;
  showTitleBar?: boolean;
}

export function PageLayoutHeader(props: PageLayoutHeaderProps) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return isLargeScreen ? (
    <DesktopHeader {...props} />
  ) : (
    <MobileHeader {...props} />
  );
}

function MobileHeader({
  title,
  actions,
  isLoading = false,
}: PageLayoutHeaderProps) {
  return (
    <div className={classList(styles.header, styles.mobile)}>
      <div className={styles.titleBar}>
        <BackLink showLabel={false} />
        <h1 className={styles.title}>{title}</h1>
        <div></div>
      </div>
      {actions && <div className={styles.actionBar}>{actions}</div>}
      <LoadingBar isLoading={isLoading} />
    </div>
  );
}

function DesktopHeader({
  title,
  actions,
  isLoading = false,
  showTitleBar = false,
}: PageLayoutHeaderProps) {
  return (
    <div className={classList(styles.header, styles.desktop)}>
      {showTitleBar && (
        <div className={styles.titleBar}>
          <div className={styles.backLinkContainer}>
            <BackLink showLabel={true} />
            <span className={styles.backLink__separator}>|</span>
          </div>
          <h1 className={styles.title}>{title}</h1>
        </div>
      )}
      {actions && <div className={styles.actionBar}>{actions}</div>}
      <LoadingBar isLoading={isLoading} />
    </div>
  );
}

function LoadingBar({ isLoading }: { isLoading: boolean }) {
  return (
    isLoading && (
      <div className={styles.loadingBar}>
        <LinearProgress color="inherit" />
      </div>
    )
  );
}

function BackLink({ showLabel }: { showLabel: boolean }) {
  const t = useTranslation();
  const navigate = useNavigate();
  return (
    <Link
      to=".."
      onClick={async (event) => {
        event.preventDefault();
        await navigate(-1);
      }}
      className={styles.backLink}
    >
      <IconButton size="small">
        <BackIcon />
      </IconButton>
      {showLabel && <span className={styles.backLink__label}>{t.back}</span>}
    </Link>
  );
}
